import "./widgetLg.css";

export default function WidgetLg() {

  const Button = ({ type }) => {
    return <button className={"widgetLgButton " + type}>{type}</button>;
  };

  return (
    <div className="widgetLg">
      <h3 className="widgetLgTitle">Latest Transactions</h3>
      <table className="widgetLgTable">
      <tbody>
        <tr className="widgetLgTr">
          <th className="widgetLgTh">Customer</th>
          <th className="widgetLgTh">Date</th>
          <th className="widgetLgTh">Amount</th>
          <th className="widgetLgTh">Status</th>
        </tr>
        
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img
              src="https://images.pexels.com/photos/2709388/pexels-photo-2709388.jpeg?cs=srgb&dl=pexels-ike-louie-natividad-2709388.jpg&fm=jpg"
              alt=""
              className="widgetLgImg"
            />
            <span className="widgetLgName">Susan Carol</span>
          </td>
          <td className="widgetLgDate">2 jun 2021</td>
          <td className="widgetLgAmount">$122.00</td>
          <td className="widgetLgStatus">
            <Button type="Approved"/>
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img
              src="https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?cs=srgb&dl=pexels-italo-melo-2379005.jpg&fm=jpg"
              alt=""
              className="widgetLgImg"
            />
            <span className="widgetLgName">Carlos Pinto</span>
          </td>
          <td className="widgetLgDate">10 jun 2021</td>
          <td className="widgetLgAmount">$152.00</td>
          <td className="widgetLgStatus">
            <Button type="Declined"/>
          </td>
          
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img
              src="https://images.pexels.com/photos/1239288/pexels-photo-1239288.jpeg?cs=srgb&dl=pexels-daniel-xavier-1239288.jpg&fm=jpg"
              alt=""
              className="widgetLgImg"
            />
            <span className="widgetLgName">Valentina Ortiz</span>
          </td>
          <td className="widgetLgDate">15 jun 2021</td>
          <td className="widgetLgAmount">$180.00</td>
          <td className="widgetLgStatus">
            <Button  type="Pending"/>
          </td>
        </tr>
        <tr className="widgetLgTr">
          <td className="widgetLgUser">
            <img
              src="https://images.pexels.com/photos/2787341/pexels-photo-2787341.jpeg?cs=srgb&dl=pexels-ali-pazani-2787341.jpg&fm=jpg"
              alt=""
              className="widgetLgImg"
            />
            <span className="widgetLgName">Gloria Perez</span>
          </td>
          <td className="widgetLgDate">25 jun 2021</td>
          <td className="widgetLgAmount">$200.00</td>
          <td className="widgetLgStatus">
            <Button type="Approved"/>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
  );
}
