import './featuredInfo.css';

import { ArrowDownwardSharp, ArrowUpwardSharp } from '@mui/icons-material/';

export default function FeaturedInfo() {
  return (
    <div className="featured">
      <div className="featuredItem">
        <span className="featuredTitle">
          Revanue
        </span>
        <div className="featuredMoneyContainer">
          <div className="featuredMoney">$2,415</div>
          <span className="featuredMoneyRate">
            -11.4 <ArrowDownwardSharp className="featuredIcon negative"/>
          </span>
        </div>
        <span className="featuredSub">
          Compared to last month
        </span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">
          Sales
        </span>
        <div className="featuredMoneyContainer">
          <div className="featuredMoney">$4,415</div>
          <span className="featuredMoneyRate">
            -1.4 <ArrowDownwardSharp className="featuredIcon negative"/>
          </span>
        </div>
        <span className="featuredSub">
          Compared to last month
        </span>
      </div>
      <div className="featuredItem">
        <span className="featuredTitle">
          Cost
        </span>
        <div className="featuredMoneyContainer">
          <div className="featuredMoney">$2,225</div>
          <span className="featuredMoneyRate">
            +2.4 <ArrowUpwardSharp className="featuredIcon" />
          </span>
        </div>
        <span className="featuredSub">
          Compared to last month
        </span>
      </div>
    </div>
  )
}
