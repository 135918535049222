import React from 'react'
import './topbar.css'

import {NotificationsNone,Language, Settings}  from '@mui/icons-material/';


export default function Topbar() {
  return (
    <div className="topbar">
    <div className="topbarWrapper">
      <div className="topLeft">
        <span className="logo">Cleaning And Supplies</span>
      </div>
      <div className="topRight">
        <div className="topbarIconContainer">
          <NotificationsNone /> 
          <span className="topIconBadge">2</span> 
        </div>
        <div className="topbarIconContainer">
          <Language /> 
          <span className="topIconBadge">2</span> 
        </div>
        <div className="topbarIconContainer">
          <Settings /> 
        </div>
        <img src="https://images.pexels.com/photos/8686318/pexels-photo-8686318.jpeg?cs=srgb&dl=pexels-milad-farhani-8686318.jpg&fm=jpg" alt="" className="topAvatar" />
      </div>
    </div>
  </div>
  )
}
