 export const userData = [
  {
    name: 'Jan',
    "Active User": 4000,
  },
  {
    name: 'Feb',
    "Active User": 3000,
 
  },
  {
    name: 'Mar',
    "Active User": 2000,
 
  },
  {
    name: 'Apr',
    "Active User": 2780,
 
  },
  {
    name: 'May',
    "Active User": 1890,

  },
  {
    name: 'Jun',
    "Active User": 2390,
   
  },
  {
    name: 'Jul',
    "Active User": 2490,
  
  },
  {
    name: 'Agu',
    "Active User": 3490,
  
  },
  {
    name: 'Sep',
    "Active User": 1490,
  
  },
  {
    name: 'Oct',
    "Active User": 2490,
  
  },
  {
    name: 'Nov',
    "Active User": 3090,
  
  },
  {
    name: 'Dec',
    "Active User": 300,
  
  },
];


export const productData = [
  {
    name: 'Jan',
    "Sales": 4000,
  },
  {
    name: 'Feb',
    "Sales": 3000,
 
  },
  {
    name: 'Mar',
    "Sales": 2000,
 
  },

];




export const userRows = [
  {
    id: 1,
    username: "Jon Snow",
    avatar:
      "https://images.pexels.com/photos/2379005/pexels-photo-2379005.jpeg?cs=srgb&dl=pexels-italo-melo-2379005.jpg&fm=jpg",
    email: "jon@gmail.com",
    status: "active",
    transaction: "$120.00",
  },
  {
    id: 2,
    username: "Laura Stuart",
    avatar:
      "https://images.pexels.com/photos/3586798/pexels-photo-3586798.jpeg?cs=srgb&dl=pexels-mateus-souza-3586798.jpg&fm=jpg",
    email: "laura@gmail.com",
    status: "active",
    transaction: "$120.00",
  },
  {
    id: 3,
    username: "Nancy Reinols",
    avatar:
      "https://images.pexels.com/photos/3220360/pexels-photo-3220360.jpeg?cs=srgb&dl=pexels-nichole-sebastian-3220360.jpg&fm=jpg",
    email: "nancy@gmail.com",
    status: "active",
    transaction: "$120.00",
  },
  {
    id: 4,
    username: "Sami Cano",
    avatar:
      "https://images.pexels.com/photos/3310695/pexels-photo-3310695.jpeg?cs=srgb&dl=pexels-ike-louie-natividad-3310695.jpg&fm=jpg",
    email: "sami@gmail.com",
    status: "active",
    transaction: "$120.00",
  },
  {
    id: 5,
    username: "Sami Cano",
    avatar:
      "https://images.pexels.com/photos/3310695/pexels-photo-3310695.jpeg?cs=srgb&dl=pexels-ike-louie-natividad-3310695.jpg&fm=jpg",
    email: "sami@gmail.com",
    status: "active",
    transaction: "$120.00",
  },
  {
    id: 6,
    username: "Sami Cano",
    avatar:
      "https://images.pexels.com/photos/3310695/pexels-photo-3310695.jpeg?cs=srgb&dl=pexels-ike-louie-natividad-3310695.jpg&fm=jpg",
    email: "sami@gmail.com",
    status: "active",
    transaction: "$120.00",
  },
  {
    id: 7,
    username: "Sami Cano",
    avatar:
      "https://images.pexels.com/photos/3310695/pexels-photo-3310695.jpeg?cs=srgb&dl=pexels-ike-louie-natividad-3310695.jpg&fm=jpg",
    email: "sami@gmail.com",
    status: "active",
    transaction: "$120.00",
  },
  {
    id: 8,
    username: "Sami Cano",
    avatar:
      "https://images.pexels.com/photos/3310695/pexels-photo-3310695.jpeg?cs=srgb&dl=pexels-ike-louie-natividad-3310695.jpg&fm=jpg",
    email: "sami@gmail.com",
    status: "active",
    transaction: "$120.00",
  },
  {
    id: 9,
    username: "Sami Cano",
    avatar:
      "https://images.pexels.com/photos/3310695/pexels-photo-3310695.jpeg?cs=srgb&dl=pexels-ike-louie-natividad-3310695.jpg&fm=jpg",
    email: "sami@gmail.com",
    status: "active",
    transaction: "$220.00",
  },
  {
    id: 10,
    username: "Sami Cano",
    avatar:
      "https://images.pexels.com/photos/3310695/pexels-photo-3310695.jpeg?cs=srgb&dl=pexels-ike-louie-natividad-3310695.jpg&fm=jpg",
    email: "sami@gmail.com",
    status: "active",
    transaction: "$320.00",
  },
];



export const productRows = [
  {
    id: 1,
    name: "Apple Airpods",
    img:
      "https://images.pexels.com/photos/164827/pexels-photo-164827.jpeg?cs=srgb&dl=pexels-pixabay-164827.jpg&fm=jpg",
    stock: 123,
    status: "active",
    price: "$120.00",
  },
  {
    id: 2,
    name: "Apple Airpods",
    img:
      "https://images.pexels.com/photos/164827/pexels-photo-164827.jpeg?cs=srgb&dl=pexels-pixabay-164827.jpg&fm=jpg",
    stock: 123,
    status: "active",
    price: "$120.00",
  },
  {
    id: 3,
    name: "Apple Airpods",
    img:
      "https://images.pexels.com/photos/164827/pexels-photo-164827.jpeg?cs=srgb&dl=pexels-pixabay-164827.jpg&fm=jpg",
    stock: 123,
    status: "active",
    price: "$120.00",
  },
  {
    id: 4,
    name: "Apple Airpods",
    img:
      "https://images.pexels.com/photos/164827/pexels-photo-164827.jpeg?cs=srgb&dl=pexels-pixabay-164827.jpg&fm=jpg",
    stock: 123,
    status: "active",
    price: "$120.00",
  },
  {
    id: 5,
    name: "Apple Airpods",
    img:
      "https://images.pexels.com/photos/164827/pexels-photo-164827.jpeg?cs=srgb&dl=pexels-pixabay-164827.jpg&fm=jpg",
    stock: 123,
    status: "active",
    price: "$120.00",
  },
  {
    id: 6,
    name: "Apple Airpods",
    img:
      "https://images.pexels.com/photos/164827/pexels-photo-164827.jpeg?cs=srgb&dl=pexels-pixabay-164827.jpg&fm=jpg",
    stock: 123,
    status: "active",
    price: "$120.00",
  },
  {
    id: 7,
    name: "Apple Airpods",
    img:
      "https://images.pexels.com/photos/164827/pexels-photo-164827.jpeg?cs=srgb&dl=pexels-pixabay-164827.jpg&fm=jpg",
    stock: 123,
    status: "active",
    price: "$120.00",
  },
  {
    id: 8,
    name: "Apple Airpods",
    img:
      "https://images.pexels.com/photos/164827/pexels-photo-164827.jpeg?cs=srgb&dl=pexels-pixabay-164827.jpg&fm=jpg",
    stock: 123,
    status: "active",
    price: "$120.00",
  },
  {
    id: 9,
    name: "Apple Airpods",
    img:
      "https://images.pexels.com/photos/164827/pexels-photo-164827.jpeg?cs=srgb&dl=pexels-pixabay-164827.jpg&fm=jpg",
    stock: 123,
    status: "active",
    price: "$120.00",
  },
  {
    id: 10,
    name: "Apple Airpods",
    img:
      "https://images.pexels.com/photos/164827/pexels-photo-164827.jpeg?cs=srgb&dl=pexels-pixabay-164827.jpg&fm=jpg",
    stock: 123,
    status: "active",
    price: "$120.00",
  },

  
];

