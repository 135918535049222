import { Visibility } from "@mui/icons-material"
import "./widgetSm.css"

export default function WidgetSm() {
  return (
    <div className="widgetSm">
      <span className="widgetSmTitle">New Join Members</span>
      <ul className="widgetSmList">
        <li className="widgetSmListItem">
          <img src="https://images.pexels.com/photos/1840608/pexels-photo-1840608.jpeg?cs=srgb&dl=pexels-marcio-bordin-1840608.jpg&fm=jpg" alt="" className="widgetSmImg"   />
          <div className="widgetSmUser">
          <span className="widgetSmUsername">Anna Keller</span>
          <span className="widgetSmUserTitle">Software Engineer</span>
          </div>
          <button className="widgetSmButton">
          <Visibility className="widgetSmIcon"/>
          Display
          </button>
        </li>
        <li className="widgetSmListItem">
          <img src="https://images.pexels.com/photos/3310695/pexels-photo-3310695.jpeg?cs=srgb&dl=pexels-ike-louie-natividad-3310695.jpg&fm=jpg" alt="" className="widgetSmImg"   />
          <div className="widgetSmUser">
          <span className="widgetSmUsername">Anna Keller</span>
          <span className="widgetSmUserTitle">Software Engineer</span>
          </div>
          <button className="widgetSmButton">
          <Visibility className="widgetSmIcon"/>
          Display
          </button>
        </li>
        <li className="widgetSmListItem">
          <img src="https://images.pexels.com/photos/2083751/pexels-photo-2083751.jpeg?cs=srgb&dl=pexels-juliana-stein-2083751.jpg&fm=jpg" alt="" className="widgetSmImg"   />
          <div className="widgetSmUser">
          <span className="widgetSmUsername">Anna Keller</span>
          <span className="widgetSmUserTitle">Software Engineer</span>
          </div>
          <button className="widgetSmButton">
          <Visibility className="widgetSmIcon"/>
          Display
          </button>
        
        </li>
        
        <li className="widgetSmListItem">
          <img src="https://images.pexels.com/photos/415829/pexels-photo-415829.jpeg?cs=srgb&dl=pexels-pixabay-415829.jpg&fm=jpg" alt="" className="widgetSmImg"   />
          <div className="widgetSmUser">
          <span className="widgetSmUsername">Anna Keller</span>
          <span className="widgetSmUserTitle">Electrical Engineer</span>
          </div>
          <button className="widgetSmButton">
          <Visibility className="widgetSmIcon"/>
          Display
          </button>
        
        </li>

        <li className="widgetSmListItem">
          <img src="https://images.pexels.com/photos/25756/pexels-photo.jpg?cs=srgb&dl=pexels-rene-asmussen-25756.jpg&fm=jpg" alt="" className="widgetSmImg"   />
          <div className="widgetSmUser">
          <span className="widgetSmUsername">Anna Keller</span>
          <span className="widgetSmUserTitle">Mecanical Engineer</span>
          </div>
          <button className="widgetSmButton">
          <Visibility className="widgetSmIcon"/>
          Display
          </button>
        
        </li>
      </ul>
    </div>
  )
}
